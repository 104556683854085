import * as React from "react"

import "../styles/styles.scss"

const PageNotFound = () => {
  return (
    <div className="PageNotFound">
      <h1 className="heading">Oops, that page doesn't exist</h1>
    </div>
  )
}

export default PageNotFound
